<template>
	<Metier name="mecanique" title="Mécanique" :main-menu="[{title: 'Contremaître principal Mécanique', missions: ['Anime et coordonne l\'activité de l\'équipe technique dont il a la charge', 'Définit la politique de maintenance mécanique', 'Assure la bonne collaboration entre les équipes de maintenance mécanique et de maintenance courante', 'Participe à la disponibilité du matériel en s\'assurant de son bon état et en proposant des améliorations'], competences: ['Formation en Mécanique', 'Expérience du fonctionnement mécanique en raffinerie', 'Connaissance de la législation et des règles de sécurité, des procédures administratives', 'Connaissance des équipements et des procédés'], qualites: ['Leadership', 'Rigueur', 'Organisation', 'Esprit de synthèse']}, {title: 'Technicien Mécanique', missions: ['Assure le suivi des machines en lien avec les constructeurs et préconise les interventions', 'Mène des études techniques en vue de l\'amélioration des machines', 'Établit les procédures et fait des préconisations pour les travaux mécaniques'], competences: ['Formation en mécanique générale ou maintenance industrielle', 'Connaissance de la législation et des règles de sécurité, des procédures administratives', 'Connaissance des équipements et des procédés'], qualites: ['Organisation ', 'Esprit de synthèse', 'Esprit d\'équipe', 'Rigueur']}]">
	</Metier>
</template>

<script>
	export default {
		components: {
			Metier: () => import('@/components/Metier.vue'),
		},
	}
</script>
